<template>
  <RestrictedTournament
    v-if="tournament.restricted"
    :tournament="tournament"
  ></RestrictedTournament>
  <v-container v-else-if="!tournament" fill-height>
    <v-row justify="center">
      <v-progress-circular color="white" indeterminate></v-progress-circular>
    </v-row>
  </v-container>
  <v-container v-else class="ma-0 pa-0">
    <v-card style="border-radius: 0;" class="px-2" color="tournamentDetail">
      <v-card-text
        class="d-flex justify-center align-center pa-0 tournamentDetailTxt--text"
      >
        <v-img
          class="my-2 rounded"
          width="120px"
          :height="isSquareIcon ? '92px' : '160px'"
          :src="gameIcon(tournament.game)"
          :aspect-ratio="isSquareIcon ? 4 / 3 : 3 / 4"
          contain
        ></v-img>
        <v-container class="mx-2 px-0 caption">
          <v-row dense>
            <v-col
              v-for="(detail, detailName) in tournament.detailsGrid"
              :key="detailName"
              class="text-center"
              cols="6"
            >
              <v-icon
                v-if="detailName === 'Players'"
                color="tournamentDetailTxt"
                class="d-block"
                >mdi-account-group</v-icon
              >
              <h4
                v-else-if="detailName === 'PayoutType'"
                class="text-capitalize"
              >
                {{ $strResourceService(detail.stringKey) }}
              </h4>
              <h4 v-else class="text-capitalize">
                {{ $str(detail.stringKey) }}
              </h4>
              {{
                detailName === "Prize Pool"
                  ? $tournamentCur(detail.value)
                  : detail.value
              }}
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-text
        v-if="tournament.statusTimingText"
        class="d-flex justify-left align-left pa-0 tournamentDetailTxt--text"
      >
        {{
          `${tournament.statusTimingLabelText} ${tournament.statusTimingText}`
        }}
      </v-card-text>
      <TournamentActions :tournament="tournament"></TournamentActions>
    </v-card>

    <v-expansion-panels accordion flat>
      <v-expansion-panel>
        <v-expansion-panel-header color="tournamentTabInactive">{{
          $str("TournamentLeaderboard")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <TournamentLeaderboard
            :details="tournament.DetailData"
          ></TournamentLeaderboard>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header color="tournamentTabInactive">{{
          $str("TournamentPrizes")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <TournamentPrizes :details="tournament.DetailData"></TournamentPrizes>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="tournament.refundEnabled">
        <v-expansion-panel-header color="tournamentTabInactive">{{
          $str("Refund")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <Refund :tournament="tournament"></Refund>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import TournamentLeaderboard from "@/components/TournamentLeaderboard.vue";
import TournamentPrizes from "@/components/TournamentPrizes.vue";
import RestrictedTournament from "@/components/RestrictedTournament.vue";
import Refund from "@/components/Refund";
import TournamentActions from "@/components/TournamentActions.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    TournamentLeaderboard,
    TournamentPrizes,
    RestrictedTournament,
    Refund,
    TournamentActions
  },
  props: {
    tournament: { type: Object, required: true }
  },
  computed: {
    ...mapGetters("balances", ["withdrawable"]),
    ...mapGetters("tournaments", ["currentServerTime"]),
    ...mapGetters("games", ["gameIcon"]),
    ...mapGetters("customizations", ["lobbyIconType"]),
    isSquareIcon() {
      return this.lobbyIconType === CDK.LobbyIconTypes.square;
    }
  }
};
</script>
<style scoped>
::v-deep .v-expansion-panels {
  z-index: 0 !important;
}

::v-deep .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 1vw;
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 0 2vw;
  margin-bottom: 1vw;
}

.v-expansion-panel-header {
  border-radius: 1vw;
  margin-bottom: 0.25rem;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  margin-bottom: 0;
}
</style>
