<template>
  <v-dialog
    v-model="dialog"
    overlay-opacity="0.9"
    persistent
    width="300"
    content-class="tournament"
  >
    <v-card color="tournamentDetail ">
      <v-card-title
        class="tournamentSuccess--text text-uppercase font-weight-bold"
        ><v-icon color="tournamentSuccess" x-large class="ml-n2 mr-2"
          >mdi-clipboard-edit-outline</v-icon
        >
        {{ $str("CreateAliasTitle") }}</v-card-title
      ><v-card-text
        ><v-container class="py-0 tournamentDetailTxt--text"
          ><v-row class="mb-2">
            {{ $str("CreateAliasMainText1") }}
          </v-row>
          <v-row>
            {{ $str("CreateAliasMainText2") }}
          </v-row></v-container
        ></v-card-text
      >
      <v-form v-model="isValid">
        <v-container class="py-0 my-0"
          ><v-text-field
            v-model="alias"
            class="required"
            dense
            outlined
            light
            :rules="[rules.minlength]"
            :error-messages="aliasErrors"
            :placeholder="$str('CreateAliasSelectMsg')"
            @input="onAliasChanged"
          ></v-text-field
        ></v-container>
      </v-form>
      <v-container class="py-0 my-0"
        ><v-row justify="center"
          ><v-card-actions
            ><v-col cols="6"
              ><v-btn
                color="buttonSecondary buttonSecondaryTxt--text"
                width="100"
                @click="goHome"
                >{{ $str("Cancel") }}</v-btn
              ></v-col
            ><v-col cols="6"
              ><v-btn
                :disabled="waiting || !isValid"
                color="button buttonTxt--text"
                width="100"
                light
                @click="createAlias(alias)"
                >{{ $str("CreateAliasCreate") }}</v-btn
              ></v-col
            ></v-card-actions
          ></v-row
        ></v-container
      >
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: true,
      alias: "",
      aliasErrors: [],
      isValid: false,
      lastBadAlias: "",
      rules: {
        required: value =>
          !!value || this.getStringByKey("CreateAliasCharLimits"),
        minlength: value =>
          (value.length >= 6 && value.length <= 20) ||
          this.getStringByKey("CreateAliasCharLimits")
      },
      waiting: false
    };
  },
  computed: {
    ...mapGetters("stringTable", ["getStringByKey"])
  },
  methods: {
    ...mapActions("dialogs", ["showSuccess"]),
    async createAlias() {
      this.waiting = true;
      const success = await this.$store.dispatch(
        "tournaments/createPlayerAlias",
        this.alias
      );
      this.waiting = false;
      if (success) {
        this.dialog = false;
        this.showSuccess({
          successMessage: this.getStringByKey("CreateAliasSuccessMessage")
        });
        this.lastBadAlias = "";
      } else {
        this.aliasErrors = this.getStringByKey("CreateAliasErrorMessage");
        this.lastBadAlias = this.alias;
      }
    },
    onAliasChanged() {
      if (this.lastBadAlias !== "" && this.alias === this.lastBadAlias) {
        this.aliasErrors = this.getStringByKey("CreateAliasErrorMessage");
      } else {
        this.aliasErrors = [];
      }
    }
  }
};
</script>
