<template>
  <v-container v-if="!connected || !playForMoney" fill-height>
    <v-row justify="center">
      <v-progress-circular color="white" indeterminate></v-progress-circular>
    </v-row>
    <ConfirmSwitchToReal />
  </v-container>
  <v-container v-else>
    <TournamentViewHeader @openSortandFilters="closeOpenTournaments" />
    <SwitchToBaseCurrency />
    <v-row id="toggleButtons" dense :class="{ fixed: isFixed }">
      <v-col cols="6"
        ><v-btn
          :class="{ active: selectedTournaments === 'available' }"
          width="100%"
          value="available"
          color="tournamentTabTxt--text "
          @click="() => setSelectedTournaments('available')"
          >{{ $str("DetailViewAvailable") }}</v-btn
        ></v-col
      >
      <v-col cols="6"
        ><v-btn
          :class="{ active: selectedTournaments === 'enrolled' }"
          width="100%"
          value="enrolled"
          color="tournamentTabTxt--text"
          @click="() => setSelectedTournaments('enrolled')"
          >{{ $str("TournamentEnrolled") }}</v-btn
        ></v-col
      >
    </v-row>

    <v-container v-show="showNoAvailableTournamentsMessage" class="pa-0">
      <v-card flat color="transparent">
        <v-card-title class="pl-1 noAvailableTournaments">
          <span>{{ $str("TournamentNoAvailable") }}.</span>
          <span>{{ $str("TournamentPleaseCheckAgain") }}.</span>
        </v-card-title>
      </v-card>
    </v-container>

    <v-container v-show="showNoEnrolledTournamentsMessage" class="pa-0">
      <v-card flat color="transparent">
        <v-card-title class="pl-1 noAvailableTournaments">
          <span class="center">{{ $str("NoEnrollmentsText") }}.</span>
        </v-card-title>
      </v-card>
    </v-container>
    <v-layout v-if="loading" align-center justify-center fill-height>
      <v-progress-circular color="grey" indeterminate></v-progress-circular>
    </v-layout>

    <v-expansion-panels
      ref="tournamentPanels"
      v-model="selectedTournamentIndex"
      flat
    >
      <v-expansion-panel
        v-for="(tournament, index) in tournamentsFiltered"
        v-show="
          selectedTournaments === 'available'
            ? true
            : tournament.DetailData.PlayerDetails.IsPlayerRegistered
        "
        :key="index"
      >
        <v-expansion-panel-header
          :id="`tournament${tournament.TournamentId}`"
          style="box-shadow: 0px 2px 10px 1px rgb(0 0 0 / 50%); z-index: 1"
        >
          <v-img
            width="100"
            contain
            class="rounded"
            :src="gameIcon(tournament.game)"
            :lazy-src="isSquareIcon ? lazyLoadingImage : null"
            :aspect-ratio="isSquareIcon ? 4 / 3 : 3 / 4"
            ><v-overlay v-if="tournament.restricted" z-index="0" absolute>
              <v-icon large>mdi-lock</v-icon>
            </v-overlay></v-img
          >
          <TournamentTile
            :name="tournament.name"
            :start="tournament.startDate"
            :status="tournament.status"
            :buy-in="tournament.buyIn"
        /></v-expansion-panel-header>
        <TournamentLateRegistrationBanner :tournament="tournament" />
        <v-expansion-panel-content
          ><TournamentDetails :tournament="tournament"
        /></v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <CreateAliasDialog
      v-if="connected && playerAlias === '' && playForMoney"
    ></CreateAliasDialog>
  </v-container>
</template>

<script>
import TournamentDetails from "@/components/TournamentDetails.vue";
import CreateAliasDialog from "@/components/Dialogs/CreateAliasDialog";
import TournamentTile from "@/components/TournamentTile.vue";
import TournamentViewHeader from "@/components/TournamentViewHeader.vue";
import TournamentLateRegistrationBanner from "@/components/TournamentLateRegistrationBanner.vue";
import { TournamentStatus } from "@/services/casino";
import SwitchToBaseCurrency from "@/components/TournamentsLobby/SwitchToBaseCurrency";
import ConfirmSwitchToReal from "@/components/TournamentsLobby/ConfirmSwitchToReal";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    TournamentDetails,
    CreateAliasDialog,
    TournamentTile,
    TournamentViewHeader,
    TournamentLateRegistrationBanner,
    SwitchToBaseCurrency,
    ConfirmSwitchToReal
  },
  data() {
    return {
      clockInterval: null,
      refreshInterval: null,
      isFixed: false,
      selectedTournamentIndex: undefined
    };
  },
  computed: {
    ...mapGetters("tournaments", [
      "connected",
      "playerAlias",
      "tournaments",
      "selectedTournaments",
      "tournamentsFiltered",
      "hasEnrolledTournaments",
      "showTournamentId",
      "tournamentsLoaded"
    ]),
    ...mapGetters("games", ["gameIcon"]),
    ...mapGetters("customizations", ["lazyLoadingImage", "lobbyIconType"]),
    ...mapGetters("session", ["playForMoney"]),
    ...mapGetters("balances", ["balance"]),
    ...mapGetters("customizations", ["tournamentRefreshRate"]),
    ...mapGetters("dialogs", ["isAnyDialogOpen"]),
    showNoAvailableTournamentsMessage() {
      return (
        this.tournamentsLoaded &&
        this.selectedTournaments === "available" &&
        !this.tournaments.length
      );
    },
    showNoEnrolledTournamentsMessage() {
      return (
        this.tournamentsLoaded &&
        this.selectedTournaments === "enrolled" &&
        !this.hasEnrolledTournaments
      );
    },
    loading() {
      return !this.tournamentsLoaded;
    },
    isSquareIcon() {
      return this.lobbyIconType === CDK.LobbyIconTypes.square;
    }
  },
  watch: {
    async playerAlias() {
      // will request tournaments in case playerAlias is set after it is checked in created hook
      if (this.playerAlias) {
        await this.requestTournaments();
      }
      this.setSelectedTournaments();
    },
    /** Detect when selected tournament changes and attempt to scroll it into view */
    selectedTournamentIndex(index) {
      if (index !== undefined) {
        this.scrollToTournamentAtIndex(index);
      }
    },
    /**
     * This value is set when the showTournament action is triggered, indicating which
     * tournament should be shown.
     */
    showTournamentId(tournamentId) {
      if (tournamentId !== null) {
        // If tournaments are loaded, go ahead and show it. Otherwise, we will check this
        // value once tournaments are loaded, in the tournamentsLoaded watcher below
        if (this.tournamentsLoaded) {
          this.showTournament(tournamentId);
        }
      }
    },
    tournamentsLoaded(loaded) {
      if (loaded) {
        // Cache the value from the store in a local variable so we don't
        // try to look it up again after the setTimeout
        const tournamentId = this.showTournamentId;

        if (tournamentId !== null) {
          // Wait until next frame, so the tournament panels can be created
          window.setTimeout(() => {
            this.showTournament(tournamentId);
          });
        }
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollHandler);
    // prevent memory leak
    clearInterval(this.clockInterval);
    clearInterval(this.refreshInterval);
  },
  async created() {
    if (this.playerAlias) {
      await this.requestTournaments();
    }
    this.setSelectedTournaments();
    window.addEventListener("scroll", this.scrollHandler);
    this.refreshTournaments();
    this.initializeServerTime();

    if (this.tournamentsLoaded && this.showTournamentId) {
      window.setTimeout(() => this.showTournament(this.showTournamentId));
    }
  },
  methods: {
    ...mapActions("dialogs", ["showLiveChat"]),
    ...mapActions("tournaments", [
      "requestTournaments",
      "setSelectedTournaments",
      "requestCurrentServerTimeAndTimeZone",
      "updateTime",
      "showTournamentDone",
      "requestTournamentCouponPrizes"
    ]),
    refreshTournaments() {
      this.refreshInterval = setInterval(() => {
        this.requestTournaments();
        this.requestCurrentServerTimeAndTimeZone();
        this.requestTournamentCouponPrizes();
      }, this.tournamentRefreshRate);
    },
    initializeServerTime() {
      this.clockInterval = setInterval(() => {
        this.updateTime();
      }, 1000);
    },
    scrollHandler() {
      const toggleButtons = document.getElementById("toggleButtons");
      if (window.scrollY > toggleButtons.offsetTop) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
    scrollToTournamentAtIndex(index) {
      const panel = this.$refs.tournamentPanels.$children[index]?.$el;
      if (!panel) {
        window.console.warn("Could not find panel at index: " + index + "!");
        return;
      }

      const tournamentExpandWait = 200;
      const dialogWait = 1000;

      // The create alias success dialog will prevent us from scrolling,
      // so wait a bit longer before trying to scroll if any dialogs are open.
      const scrollWait = this.isAnyDialogOpen
        ? dialogWait
        : tournamentExpandWait;

      const doScroll = () => {
        // An open dialog will cover the panels and prevent us from scrolling
        if (this.isAnyDialogOpen) {
          window.console.warn(
            "Could not scroll to opened tournament because a dialog was covering it"
          );
          return;
        }

        // When this.isFixed is true, the tournament view header is not visible,
        // so don't include it in the offset calculation
        const headerOffset = this.isFixed
          ? 0
          : document.getElementById("tournamentViewHeader").offsetHeight;

        const totalOffset =
          document.getElementById("mainViewHeader").offsetHeight +
          headerOffset +
          document.getElementById("toggleButtons").offsetHeight;

        const tournamentY = panel.getBoundingClientRect().y;

        window.scrollTo(0, tournamentY + window.scrollY - totalOffset);
      };

      window.setTimeout(doScroll, scrollWait);
    },
    /* Select and show a tournament by tournamentId. If the tournament can't
     * be found, or is closed, etc., just display the "available" list
     */
    showTournament(tournamentId) {
      if (tournamentId === null) {
        this.showTournamentDone();
        return;
      }

      // Always switch to "available" view before trying to show tournament
      this.setSelectedTournaments("available");

      const index = this.tournamentsFiltered.findIndex(
        t => t.TournamentId === tournamentId
      );

      if (index === -1) {
        window.console.warn(
          `ShowTournament: Could not find tournament with ID: ${tournamentId}`
        );
        this.showTournamentDone();
        return;
      }

      const tournament = this.tournamentsFiltered[index];
      if (
        tournament.StatusId < TournamentStatus.Registering ||
        tournament.StatusId > TournamentStatus.Running
      ) {
        // Tournament is not registering yet or already complete, don't try to show it, just show available tournaments
        this.showTournamentDone();
        return;
      }

      // Selects the actual tournament panel in the expansion panels
      this.selectedTournamentIndex = index;

      // Notify the store that we've shown the tournament so the value can be cleared
      this.showTournamentDone();
    },
    closeOpenTournaments() {
      this.selectedTournamentIndex = undefined;
    }
  }
};
</script>

<style scoped>
@media (min-width: 960px) {
  .container {
    max-width: 900px !important;
  }
}

h4 {
  background: white;
  border-radius: 5%;
  color: black;
}

.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined),
.theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background: var(--v-tournamentTabInactive-base);
}

.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined).active,
.theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined).active {
  background: var(--v-tournamentTab-base);
}

.v-btn:before {
  background-color: transparent;
}

.v-expansion-panels {
  z-index: 0;
}

.v-expansion-panel {
  border-radius: 2vw;
  margin-top: 2vw;
}

.v-expansion-panel-header {
  padding: 2vw;
  background: var(--v-tournament-base);
  color: var(--v-tournamentTxt-base);
  border-radius: 2vw;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--next-active
  .v-expansion-panel-header {
  border-bottom-left-radius: 2vw;
  border-bottom-right-radius: 2vw;
}

.noAvailableTournaments {
  justify-content: center;
  margin-top: 4vh;
  padding-left: 4px;
  padding-right: 4px;
}

.fixed {
  position: fixed;
  top: 80px;
  width: 95%;
  z-index: 1;
  background-color: var(--v-body-base) !important;
}

.center {
  word-break: break-word;
  text-align: center;
}
</style>
