<template>
  <v-row id="tournamentViewHeader" class="pr-2">
    <v-col class="pt-0 pb-0">
      <div class="subtitle-2 tournamentTxt--text">
        {{ $str("TournamentAlias") }}:
        <span class="font-weight-bold">{{ playerAlias }}</span>
      </div>
      <div class="subtitle-2 tournamentTxt--text">
        <span class="d-flex font-weight-bold">
          <v-icon style="font-size: inherit; padding-bottom: 0.1rem"
            >mdi-wallet</v-icon
          >
          <span id="tournamentViewBalanceAmount" class="mb-0 ml-1">
            {{ $cur(withdrawable) }}
          </span>
        </span>
      </div>
    </v-col>
    <v-col cols="4" class="pt-0 pb-0 justify-center align-center">
      <v-list-item class="pr-0">
        <v-list-item-content class="border-line text-center pa-1">
          <v-list-item-title
            v-if="displayServerTime"
            class="tournamentTxt--text"
          >
            {{ displayServerTime }}
          </v-list-item-title>
          <v-list-item-title v-else class="tournamentTxt--text">
            --:--
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col
      v-if="liveChatInfo.isEnabled"
      cols="1"
      class="pt-0 pb-0 d-flex justify-center align-center"
    >
      <v-btn
        icon
        :disabled="!customScriptAvailability"
        @click.stop="showLiveChat({locaction: 'lobby'})"
      >
        <v-icon large>mdi-wechat</v-icon>
      </v-btn>
      <!-- <div>{{ $str("Chat") }}</div> -->
    </v-col>
    <v-col cols="1" class="pt-0 pb-0 d-flex justify-center align-center">
      <v-btn icon @click.stop="openSortandFilters()">
        <v-icon large>mdi-filter</v-icon>
      </v-btn>
    </v-col>
    <v-col
      v-if="showCouponPrizes"
      cols="1"
      class="pt-0 pb-0 d-flex justify-center align-center"
    >
      <v-btn icon @click.stop="showTournamentsCouponPrizes()">
        <v-icon>$vuetify.icons.Tournaments</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters("tournaments", [
      "playerAlias",
      "displayServerTime",
      "couponPrizes"
    ]),
    ...mapGetters("balances", ["withdrawable"]),
    ...mapGetters("session", ["liveChatInfo", "customScriptAvailability"]),

    showCouponPrizes() {
      return this.couponPrizes.length > 0;
    }
  },
  methods: {
    ...mapActions("dialogs", [
      "showSortAndFilters",
      "showBalanceDetail",
      "showLiveChat",
      "showTournamentsCouponPrizes"
    ]),
    openSortandFilters() {
      this.$emit("openSortandFilters");
      this.showSortAndFilters();
    }
  }
};
</script>
<style scoped>
.border-line {
  border: solid 1px;
  border-radius: 3px;
  color: var(--v-tournamentInfoTxt-base);
}
</style>
