<template>
  <v-card
    v-if="showBanner"
    color="tournamentTab"
    class="pt-1"
    style="border-radius:2vw"
  >
    <v-card-text
      class="caption pa-0 tournamentTabTxt--text"
      style="font-weight: 500; text-align: center"
      >{{ bannerMessage }}</v-card-text
    >
  </v-card>
</template>

<script>
import { differenceInMinutes /*fromUnixTime*/ } from "date-fns";
import { mapGetters } from "vuex";

export default {
  props: {
    tournament: { type: Object, required: true }
  },
  computed: {
    ...mapGetters("tournaments", ["currentServerTime"]),
    registrationMinutesRemaining() {
      return differenceInMinutes(
        this.tournament.registrationEndDate,
        new Date(this.currentServerTime)
      );
    },
    showBanner() {
      return (
        this.tournament.DetailData.Details.StatusId === 4 &&
        !this.tournament.DetailData.PlayerDetails.IsPlayerRegistered &&
        this.registrationMinutesRemaining >= 0
      );
    },
    bannerMessageString() {
      const mins = this.registrationMinutesRemaining;
      if (mins > 60) {
        return this.$str("LateRegistration");
      } else if (mins > 1) {
        return this.$str("LateRegistrationMinutes");
      } else if (mins === 1) {
        return this.$str("LateRegistrationOneMinute");
      } else {
        return this.$str("LateRegistrationLessThanOneMinute");
      }
    },
    bannerMessage() {
      return String.format(
        this.bannerMessageString,
        this.registrationMinutesRemaining
      );
    }
  }
};
</script>
